<template>
  <div>
    <!-- <style>
      :root {
        --theme-color: {{ themeColor }};
      }
    </style>-->
    <div
      :class="{ 'theme-menu-item': true, item: true, active: isActive(item.path) }"
      v-for="(item, index) in list"
      :key="index"
    >
      <router-link :to="item.path" tag="div" class="link">
        <div class="title">{{ item.title }}</div>
        <div class="sub-title">{{ item.subTitle }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { store } from "../store";
export default {
  name: "MyMenu",
  props: { list: Array },
  data: () => {
    return {
      themeColor: store.state.themeColor,
    };
  },
  methods: {
    isActive(path) {
      let routePath = this.$route.path;
      if (routePath == path) {
        return true;
      }
      return false;
    },
  },
};
// console.log(store);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.title,
.sub-title {
  cursor: default;
}
.link {
  padding: 10px 10px;
}
.item {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  min-width: 60px;
  .title {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }
  .sub-title {
    font-size: 0.6rem;
  }
}
</style>
