<template>
  <el-row type="flex" justify="space-between" class="theme-header header">
    <el-col :xs="16" :sm="6" :md="5" :lg="5" :xl="5">
      <div class="theme-logo logo-wrap">
        <span class="logo bf bf-logo-badfox"></span>
        <router-link to="/" tag="span" class="logo-title">
          {{title}}
          <small class="theme-logo-sub-title logo-sub-title">{{subTitle}}</small>
        </router-link>

        <!-- <span class="sub-title"></span> -->
      </div>
    </el-col>
    <el-col :xs="0" :sm="18" :md="18" :lg="18" :xl="18">
      <div style="width: 100%; text-align: right;">
        <MyMenu :list="linkList"/>
      </div>
    </el-col>
    <el-col :xs="4" :sm="0" :md="0" :lg="0" :xl="0">
      <el-dropdown trigger="click" class="more theme-menu-more">
        <span class="el-dropdown-link">
          <i class="el-icon-more"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in linkList" :key="item.path">
            <router-link :to="item.path" tag="div">
              <span>{{ item.title }}</span>
            </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
  </el-row>
</template>

<script>
import { store } from "../store";
import MyMenu from "./MyMenu";
export default {
  name: "MyHeader",
  props: { title: String, subTitle: String, linkList: Array },
  data: () => {
    return {
      themeColor: store.state.themeColor
    };
  },
  components: { MyMenu }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.header {
  max-width: 1200px;
  margin: auto;
}
.logo-wrap {
  padding: 12px 0px;
  display: table;
  cursor: default;
}
.logo {
  display: table-cell;
  vertical-align: middle;
  padding-right: 4px;
  font-size: 2rem;
}
.logo-title {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  font-size: 1.4rem;
}
.logo-sub-title {
  font-weight: 500;
  font-size: 1rem;
}
.more {
  text-align: right;
  width: 100%;
  padding-top: 20px;
  font-size: 1.2rem;
  padding: 20px;
  font-size: 1.4rem;
  box-sizing: border-box;
}
</style>
