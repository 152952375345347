<template>
  <div>
    <!-- <div class="bg"></div> -->
    <el-container class="container">
      <el-header
        style="position: absolute; width: 100%; z-index: 1; height: 60px"
      >
        <MyHeader :title="title" :subTitle="subTitle" :linkList="linkList" />
      </el-header>
      <el-main style="min-height: 100vh; padding: 0px">
        <TransitionFadeLeft>
          <router-view />
        </TransitionFadeLeft>
      </el-main>
    </el-container>
    <el-container>
      <el-footer class="theme-footer-bg" style="height: fit-content">
        <div class="footer" style="max-width: 1200px; margin: 0px auto">
          <el-row>
            <el-col
              class="footer-col"
              v-for="(item, index) in footer"
              :key="index"
              :xs="24"
              :sm="12"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <h3>
                <span
                  v-if="item.icon"
                  :class="'bc ' + item.icon"
                  style="font-size: 1.6rem"
                ></span>
                {{ item.title }}
              </h3>
              <p v-for="(content, index) in item.list" :key="index">
                <template v-if="content.link">
                  <router-link
                    :to="content.link"
                    :href="content.link"
                    v-if="content.type == 'link'"
                    >{{ content.name }}</router-link
                  >
                  <a :href="content.link" v-if="!content.type">{{
                    content.name
                  }}</a>
                </template>
                <template v-if="!content.link">{{ content.name }}</template>
                <template v-if="content.desc">
                  <span>{{ " - " + content.desc }}</span>
                </template>
              </p>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            justify="space-around"
            align="bottom"
            style="height: 200px"
          >
            <el-col style="font-size: 0.7rem; text-align: center; color: #aaa"
              >访问次数 {{ visitCount }}</el-col
            >
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import TransitionFadeLeft from "../components/transition/TransitionFadeLeft.vue";
// import MyMenu from "../components/MyMenu.vue";
import MyHeader from "../components/MyHeader.vue";
import getWeekendDaysOfYear from "../tools/getWeekendDaysOfYear";

export default {
  name: "layout",
  data: () => {
    return {
      title: "BADFOX",
      subTitle: ".ME",
      version: process.env.VUE_APP_VERSION || 0,
      visitCount: "-",
      user: {},
      linkList: [
        { path: "/", title: "首页", subTitle: "Home" },
        { path: "/note", title: "笔记", subTitle: "Note" },
        { path: "/tools", title: "工具", subTitle: "Tool" },
        { path: "/photo", title: "摄影", subTitle: "Photo" },
        // { path: "/game", title: "游戏", subTitle: "game" },
        // { path: "/sprite", title: "画板", subTitle: "sprite" },
        // { path: "/gallary", title: "画廊", subTitle: "gallary" },
        { path: "/gym", title: "健身", subTitle: "Gym" },
        { path: "/algorithm", title: "算法", subTitle: "ALG" },
        // { path: "/resume", title: "简历", subTitle: "resume" },
        // { path: "/css", title: "CSS3", subTitle: "demo" }
        // { path: "/about", title: "关于", subTitle: "about" }
      ],
      footer: {
        friends: {
          icon: "bc-link",
          title: "友情链接",
          list: [
            {
              link: "https://www.jianshu.com/u/8e831b597f9f",
              name: "我的简书",
              desc: "我的一些技术笔记",
            },
            {
              link: "https://github.com/llqgit",
              name: "GitHub",
              desc: "我的 github",
            },
            {
              link: "http://book.qidian.com/info/1007105634",
              name: "勘探者：曙光",
              desc: "我的烂尾小说",
            },
            {
              link: "http://www.5ixiudou.com/",
              name: "天际",
              desc: "贱猫的网站",
            },
          ],
        },
        tech: {
          icon: "bc-rocket",
          title: "技术栈",
          list: [
            {
              link: "https://cn.vuejs.org/index.html",
              name: "vuejs",
              desc: "渐进式 JavaScript 框架",
            },
            {
              link: "https://eggjs.org/",
              name: "eggjs",
              desc: "为企业级框架和应用而生",
            },
            {
              link: "https://http://element-cn.eleme.io",
              name: "elementui",
              desc: "网站快速成型工具",
            },
            {
              link: "https://www.iconfont.cn/",
              name: "iconfont.cn",
              desc: "阿里出品 icon 图标库",
            },
            {
              link: "https://www.aliyun.com/product/oss",
              name: "aliyun.com",
              desc: "阿里云 OSS 对象存储服务",
            },
          ],
        },
        net: {
          icon: "bc-earth",
          title: "网络",
          list: [
            {
              link: "/about",
              name: "关于我们",
              desc: "好吧，关于我",
              type: "link",
            },
            {
              link: "http://www.beian.gov.cn/portal/registerSystemInfo",
              name: "吉ICP备15007270号",
            },
          ],
        },
        about: {
          icon: "bc-rili-" + moment().date(),
          title: "Copyright@2019",
          list: [
            { link: "", name: "Design by 千罹", desc: "" },
            { link: "", name: "我为自己带盐", desc: "" },
            { link: "", name: "I bring salt for myself", desc: "" },
            {
              link: "",
              name: `今年天数为 ${getWeekendDaysOfYear().days}`,
              desc: "",
            },
            {
              link: "",
              name: `今年周末天数为 ${getWeekendDaysOfYear().freeDays}`,
              desc: "",
            },
          ],
        },
      },
    };
  },
  components: {
    TransitionFadeLeft,
    // MyMenu,
    MyHeader,
  },
  mounted() {
    this.getUserInfo();
    this.getVisitCount();
  },
  created() {
    this.statisticVisit();
  },
  methods: {
    getUserInfo() {},
    logout() {},
    statisticVisit() {
      console.info("-- log visit --");
      const host = process.env.VUE_APP_HOST_URL;
      // eslint-disable-next-line no-unused-vars
      this.axios.get(host + "/statistics/visit_count", {}).then((response) => {
        console.log(response);
      });
    },
    getVisitCount() {
      console.info("-- get visit count --");
      const host = process.env.VUE_APP_HOST_URL;
      this.axios
        .get(host + "/statistics/get_visit_count", {})
        .then((response) => {
          const data = response.data;
          this.visitCount = data;
        });
    },
  },
};
</script>

<style lang="less" slot-scope>
.footer {
  padding: 20px 20px 20px 20px;
  .footer-col {
    padding: 10px;
  }
  p {
    font-size: 0.7rem;
  }
}
// 子页面内容
.layout-content {
  max-width: 1200px;
  margin: auto;
  margin-top: 80px;
  padding: 0px 10px;
}
// element ui bug
.el-row--flex {
  flex-wrap: wrap;
}
</style>
