import moment from 'moment';

let days = 0;
let freeDays = 0;

// 获取一年中的周末天数
const getWeekendDaysOfYear = () => {
  let now = moment();
  let start = now.startOf('year');
  let end = now.endOf('year');
  let days = end.dayOfYear();
  let freeDays = 0;
  console.log('calc');

  for (let i = 1; i <= days; i++) {
    let today = moment(start).add(i, 'day');
    if (today.weekday() == 6 || today.weekday() == 0) {
      freeDays++;
    }
  }
  return {
    days,
    freeDays
  };
};

if (days == 0 || freeDays == 0) {
  let calc = getWeekendDaysOfYear();
  days = calc.days;
  freeDays = calc.freeDays;
}

export default () => {
  return {
    days,
    freeDays,
  }
};